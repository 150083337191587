import { Box, Typography } from '@oresundsbron/bridge-ui';
import { cx } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { FC, useMemo } from 'react';
import { useRouter } from 'next/router';

interface PageMetadataProps {
  namespace: string;
  className?: string;
  queryNamespace?: boolean;
}

const PageMetadata: FC<PageMetadataProps> = ({
  namespace,
  className,
  queryNamespace,
}) => {
  const { t } = useTranslation([namespace, 'account', 'common'], {
    nsMode: 'fallback',
  });

  const hasPreTitle = useMemo(
    () => t(`${namespace}.layout.preTitle`) !== `${namespace}.layout.preTitle`,
    [namespace, t]
  );

  const hasTitle = useMemo(
    () => t(`${namespace}.layout.title`) !== `${namespace}.layout.title`,
    [namespace, t]
  );

  const hasDescription = useMemo(
    () =>
      t(`${namespace}.layout.description`) !==
      `${namespace}.layout.description`,
    [namespace, t]
  );

  const { query } = useRouter();

  return (
    <Box className={cx(['pb-12 pt-9', className])}>
      <Typography
        intent="title"
        as="p"
        size="sm"
        className={!hasPreTitle ? 'hidden' : 'uppercase text-neutral-500'}
      >
        {t(`${namespace}.layout.preTitle`)}
      </Typography>
      <h2 className="mb-4 text-xl font-semibold leading-tight text-blue-950 sm:text-4xl">
        {hasTitle ? t(`${namespace}.layout.title`) : ''}
        {queryNamespace ? query[namespace] : ''}
      </h2>
      <Typography
        intent="body"
        className={!hasDescription ? 'hidden' : 'text-neutral-600'}
      >
        {t(`${namespace}.layout.description`)}
      </Typography>
    </Box>
  );
};

export default PageMetadata;
