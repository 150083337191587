import { useTypeSafeMutation } from '@oresundsbron/api';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { resetAuthentication } from '../stores/auth';

export function useLogout() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const useAuth0 = process.env.NEXT_USE_AUTH0 === 'true';

  const { mutateAsync: logout } = useTypeSafeMutation(
    ['account', 'logout'],
    ({ Auth }) => Auth.logout
  );

  return useCallback(async () => {
    if (useAuth0) {
      await router.replace('/api/auth/logout');
      return;
    }

    try {
      await logout(undefined);
    } finally {
      resetAuthentication();
      queryClient.removeQueries(['account']);
      await router.replace('/account/login');
    }
  }, [logout, queryClient, router, useAuth0]);
}
